<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card
        class="no-h-padding"
        :title="$t('import.new')"
      >
        <form-wizard
          title=""
          subtitle=""
          :color="$themes.primary"
          :next-button-text="$t('layout.wizard.next')"
          :back-button-text="$t('layout.wizard.back')"
          :finish-button-text="$t('layout.wizard.finish')"
          @on-complete="submit"
        >
          <tab-content
            :title="$t('reports.wizard.data')"
            :before-change="isFileValid"
          >
            <div class="text-center">
              <p><strong>{{$t('import.file_form')}}</strong></p>
            </div>
            <div class="row">
              <div class="flex xs12 md6">
                <va-select
                  v-model="importType"
                  :label="$t('import.type')"
                  :options="importTypes"
                  :no-options-text="$t('layout.empty')"
                  text-by="name"
                  searchable
                />
              </div>
              <div class="flex xs12 md6">
                <input
                  type="file"
                  id="file"
                  ref="file"
                  :accept="acceptedTypes"
                  @change="handleFileUpload"
                />
              </div>
            </div>
            <div class="row">
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.initRow"
                  type="text"
                  placeholder="2"
                  :label="$t('import.map.row_init')"
                />
              </div>
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.dateFormat"
                  type="text"
                  placeholder="j-m-y"
                  :label="$t('import.map.birthdate_format')"
                />
              </div>
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.user.name"
                  type="text"
                  placeholder="A"
                  :label="$t('import.map.name_column')"
                />
              </div>
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.user.birthdate"
                  type="text"
                  placeholder="B"
                  :label="$t('import.map.birthdate_column')"
                />
              </div>
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.user.country"
                  type="text"
                  placeholder="C"
                  :label="$t('import.map.country_column')"
                />
              </div>
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.user.district"
                  type="text"
                  placeholder="D"
                  :label="$t('import.map.district_column')"
                />
              </div>
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.user.group"
                  type="text"
                  placeholder="E"
                  :label="$t('import.map.group_column')"
                />
              </div>
              <div class="flex xs12 md3">
                <va-input
                  color="info"
                  v-model="map.user.email"
                  type="text"
                  placeholder="F"
                  :label="$t('import.map.email_column')"
                />
              </div>
            </div>
          </tab-content>
          <tab-content :title="$t('teams.wizard.confirm')">
            <div class="flex-center text-center">
              <transition-group
                name="fade"
                mode="out-in"
              >
                <div v-show="processing" key="processing" class="text-center">
                  <looping-rhombuses-spinner
                    :color="$themes.primary"
                    :rhombus-size="15"
                  />
                  <p><strong>{{$t('import.checking_data')}}</strong></p>
                </div>
                <div v-show="!processing && !uploadError" key="data">
                  <div class="text-center">
                    <p><strong>{{$t('import.data_to_create')}}</strong></p>
                  </div>
                  <va-data-table
                    :per-page="20"
                    :fields="filteredFields"
                    :data="filteredData"
                  >
                    <template slot="notes" slot-scope="props">
                      <p v-for="(e, i) of props.rowData.notes" :key="i">{{ $t(e) }}</p>
                    </template>
                  </va-data-table>
                  <div class="text-center mt-4">
                    <p><strong>{{$t('import.data_with_errors')}}</strong></p>
                  </div>
                  <va-data-table
                    :per-page="20"
                    :fields="errorFields"
                    :data="errorData"
                  >
                    <template slot="errors" slot-scope="props">
                      <p v-for="(e, i) of props.rowData.errors" :key="i">{{ $t(e) }}</p>
                    </template>
                  </va-data-table>
                </div>
                <div
                  key="error"
                  v-show="!processing && uploadError"
                >
                  <div class="text-center">
                    <h4>{{$t('import.upload_error')}}</h4>
                    <va-button
                      color="primary"
                      @click.prevent="processFile"
                    >
                      {{$t('reports.wizard.retry')}}
                    </va-button>
                  </div>
                </div>
              </transition-group>
            </div>
          </tab-content>
        </form-wizard>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import LoopingRhombusesSpinner from 'epic-spinners/src/components/lib/LoopingRhombusesSpinner.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'import-data',
  components: {
    FormWizard,
    TabContent,
    LoopingRhombusesSpinner,
  },
  data () {
    return {
      error: false,
      processing: false,
      currentFile: null,
      uploadError: false,
      importType: '',
      acceptedTypes: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      filteredData: [],
      errorData: [],
      map: {
        initRow: 2,
        dateFormat: 'j-m-Y',
        user: {
          name: 'A',
          birthdate: 'B',
          country: 'C',
          district: 'D',
          group: 'E',
          email: 'F',
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    importTypes () {
      return [
        { id: 'users', name: this.$t('import.types.users') },
      ]
    },
    filteredFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
        },
        {
          name: 'country',
          title: this.$t('tables.headings.country'),
        },
        {
          name: 'district',
          title: this.$t('tables.headings.district'),
        },
        {
          name: 'email',
          title: this.$t('tables.headings.email'),
        },
        {
          name: 'group',
          title: this.$t('tables.headings.group'),
        },
        {
          name: '__slot:notes',
          title: this.$t('tables.headings.notes'),
        },
      ]
    },
    errorFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
        },
        {
          name: 'country',
          title: this.$t('tables.headings.country'),
        },
        {
          name: 'district',
          title: this.$t('tables.headings.district'),
        },
        {
          name: 'email',
          title: this.$t('tables.headings.email'),
        },
        {
          name: 'group',
          title: this.$t('tables.headings.group'),
        },
        {
          name: '__slot:errors',
          title: this.$t('tables.headings.errors'),
        },
      ]
    },
  },
  created () {
    this.importType = this.importTypes[0]
  },
  methods: {
    handleFileUpload () {
      const file = this.$refs.file.files[0]
      this.currentFile = file
    },
    isFileValid () {
      const valid = this.currentFile != null && this.acceptedTypes.includes(this.currentFile.type)
      if (valid) this.processFile()
      return valid
    },
    async processFile () {
      this.processing = true
      this.uploadError = false
      const form = new FormData()
      form.append('_method', 'PUT')
      form.append('type', this.importType.id)
      let map = this.map.user
      if (this.importType.id === 2) {
        map = this.map.team
      }
      map.row = this.map.initRow
      map.dateFormat = this.map.dateFormat
      form.append('map', JSON.stringify(map))
      form.append('action', 'read')
      form.append('file', this.currentFile)

      let response = null
      try {
        response = await this.$http.post('import-data', form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (e) {
        console.log('Error uploading photo', e)
        this.processing = false
        this.uploadError = true
        return
      }

      this.processing = false
      this.filteredData = response.data.data.new.slice(0)
      this.errorData = response.data.data.missing.slice(0)
    },
    async submit () {
      this.processing = true

      const data = this.filteredData.map(x => ({
        name: x.name,
        birthdate: x.date,
        country_id: x.country_id,
        district_id: x.district_id,
        groups: { _ids: [x.groups_id] },
        email: x.email,
      }))

      try {
        await this.$http.put('import-data', data)
      } catch (e) {
        console.log('Error uploading photo', e)
        this.processing = false
        this.uploadError = true
        return
      }

      this.showToast(this.$t('notifications.alerts.data_imported'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.go(0)
    },
  },
}
</script>
